<template>
  <b-card title="Tambah Promo">
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Masukkan Judul Promo"
          label-for="input-1"
        >
          <b-form-input
            v-model="judul"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Masukkan kode promo"
          label-for="input-2"
        >
          <b-form-input
            v-model="kodepromo"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Masukkan kuota"
          label-for="input-3"
        >
          <b-form-input
            v-model="kuota"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Masukkan maksimal penggunaan"
          label-for="input-4"
        >
          <b-form-input
            v-model="maksimalpenggunaan"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Masukkan maksimal potongan"
          label-for="input-5"
        >
          <b-form-input
            v-model="maksimalpotongan"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Masukkan minimal transaksi"
          label-for="input-6"
        >
          <b-form-input
            v-model="minimaltransaksi"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Masukkan potongan"
          label-for="input-7"
        >
          <b-form-input
            v-model="potongan"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Masukkan Kode Area"
          label-for="input-8"
        >
          <v-select
            v-model="selectedKodearea"
            label="label"
            :options="kodearea"
            placeholder="Pilih Kode area"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Masukkan layanan"
          label-for="input-9"
        >
          <v-select
            v-model="selectedLayanan"
            label="label"
            multiple
            :options="layanan"
            placeholder="Pilih layanan"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Masukkan metode pembayaran"
          label-for="input-10"
        >
          <v-select
            v-model="selectedMetodepembayaran"
            label="label"
            :options="metodepembayaran"
            placeholder="Pilih metode pembayaran"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Masukkan deskripsi"
          label-for="input-11"
        >
          <b-form-textarea
            v-model="deskripsi"
            placeholder="Masukkan deskripsi"
            rows="3"
            max-rows="6"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Masukkan Batas waktu"
          label-for="input-12"
        >
          <date-picker
            v-model="bataswaktu"
            type="datetime"
            class="date-width"
          />
        </b-form-group>
        <b-button
          variant="success"
          @click="tambahPromo()"
        >
          Tambah
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormInput, BCol, BRow,
  BFormTextarea, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import firebase from 'firebase/app'
import { db } from '@/firebase'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,

    vSelect,
    DatePicker,
  },
  data() {
    return {
      judul: '',
      kodepromo: '',
      kuota: '',
      maksimalpenggunaan: '',
      maksimalpotongan: '',
      minimaltransaksi: '',
      potongan: '',
      kodearea: [
        { value: 'all', label: 'All' },
      ],
      selectedKodearea: '',
      layanan: [
        { value: 'vl01', label: 'Akupuntur' },
        { value: 'vl02', label: 'Auto' },
        { value: 'vl03', label: 'Barber' },
        { value: 'vl04', label: 'Bekam' },
        { value: 'vl05', label: 'Beauty' },
        { value: 'vl06', label: 'Cleaning' },
        { value: 'vl07', label: 'Massage' },
        { value: 'vl08', label: 'Layanan AC' },
      ],
      selectedLayanan: '',
      metodepembayaran: [
        { value: 'cash', label: 'Cash' },
        { value: 'mecarepay', label: 'Mecarepay' },
      ],
      selectedMetodepembayaran: '',
      deskripsi: '',
      bataswaktu: '',
    }
  },
  methods: {
    /* eslint-disable radix */
    tambahPromo() {
      const layananArr = []
      this.selectedLayanan.forEach(vertikal => {
        layananArr.push(vertikal.value)
      })

      db.collection('promo').add({
        judul: this.judul,
        deskripsi: this.deskripsi,
        batas_waktu: this.bataswaktu,
        kode_area: this.selectedKodearea.value,
        kode_promo: this.kodepromo,
        kuota: parseInt(this.kuota),
        layanan: layananArr,
        maksimal_penggunaan: parseInt(this.maksimalpenggunaan),
        maksimal_potongan: parseInt(this.maksimalpotongan),
        metode_pembayaran: this.selectedMetodepembayaran.value,
        minimal_transaksi: parseInt(this.minimaltransaksi),
        mulai_waktu: firebase.firestore.FieldValue.serverTimestamp(),
        potongan: parseInt(this.potongan),
      }).then(() => {
        this.$router.push({ name: 'promo' })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Berhasil Ditambahkan!',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~vue2-datepicker/scss/index.scss';
</style>

<style>
  .date-width {
    width: auto;
  }
</style>
